import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ title, description, link }) => {
  return (
    <div className="p-6 md:p-12 bg-white rounded-lg shadow-md">
      <h2 className="text-xl md:text-2xl font-bold mb-4">{title}</h2>
      <p className="mb-8">{description}</p>
      <Link to={link} className="text-blue-500 font-semibold">
        Learn More
      </Link>
    </div>
  );
};

export default ServiceCard;
