import React from "react";
import AboutUs from "../components/AboutUs";
import Why from "../components/Why";
import Who from "../components/Who";
import ServicesOverview from "../components/ServicesOverview";

const About = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      <AboutUs />
      <Why />
      <Who />
      <ServicesOverview />
    </div>
  );
};

export default About;
