import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Analytics } from "@vercel/analytics/react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Web3DevPage from "./pages/Web3DevPage";
import AutomationPage from "./pages/AutomationPage";
import TrainingPage from "./pages/TrainingPage";
import OptimizationPage from "./pages/OptimizationPage";
import EggSortingOpt from "./pages/caseStudies/EggSorting";
import PickingOpt from "./pages/caseStudies/PickingOpt";
import VRP from "./pages/caseStudies/VRP";
import PatrolRouting from "./pages/caseStudies/PatrolRouting";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services/web3-dev" element={<Web3DevPage />} />
          <Route path="/services/optimization" element={<OptimizationPage />} />
          <Route path="/services/automation" element={<AutomationPage />} />
          <Route path="/services/training" element={<TrainingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/case-studies/egg-sorting" element={<EggSortingOpt />} />
          <Route path="/case-studies/order-picking" element={<PickingOpt />} />
          <Route path="/case-studies/vrp" element={<VRP />} />
          <Route
            path="/case-studies/patrol-routing"
            element={<PatrolRouting />}
          />

          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </Router>
      <Analytics />
    </>
  );
}

export default App;
