import React from "react";
import solidity from "../images/web3/solidity.jpg"; // update the path if necessary
import CaseStudiesWeb3 from "../components/CaseStudiesWeb3";
import ContactUsOverview from "../components/ContactUsOverview";

const Web3DevPage = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      <section className="py-10 text-center">
        <h1 className="text-4xl mb-4 font-bold ">
          Web3 Consulting & Development
        </h1>
        <h2 className="text-2xl mb-2">
          We utilize the latest developments in web3 to power your decentralized
          business process.
        </h2>
      </section>
      <div className="flex flex-wrap md:flex-nowrap items-center">
        <div className="prose lg:prose-xl w-full md:w-2/3">
          <p className="mb-2">
            The decentralized web, or web3, has opened up new horizons for
            businesses across industries. But navigating this new landscape can
            be challenging without expert guidance.
          </p>
          <p className="mb-2">
            At Hatamoto, we understand the transformative potential of web3
            technologies.{" "}
            <b>
              Concrete ownership of digital assets and data by yourself or your
              users is a daunting prospect.
            </b>
            {""}
            We also understand that each business is unique, with its own set of
            challenges and objectives. With a skilled team of strategists,
            business consultants, and web3 developers, we are adept at creating
            advanced solutions including smart contracts, decentralized
            applications (dApps), and complex DeFi platforms.
          </p>
          <p className="mb-2">
            Whether it's formulating a web3 strategy or integrating a particular
            blockchain technology such as crypto payments or DeFi applications
            into your existing operations, we have solid experience in both
            business processes and decentralized technologies to provide the
            guidance and insights you need to make informed decisions.
          </p>
          <p className="mb-2">
            Our development services span the entire development lifecycle, from
            ideation and design to implementation and support. We are committed
            to delivering end-to-end solutions that are secure, scalable, and
            tailored to your specific needs. We combine the power of
            cutting-edge technologies on EVM compatible blockchains like account
            abstraction, cross-chain interoperability and IPFS with innovative
            design and rigorous testing to provide the highest quality web3
            development services.s
          </p>
          <p className="mb-2">
            With Hatamoto's Web3 Consultancy Services, you gain a partner
            committed to helping you succeed in the decentralized world. Our
            goal is to ensure that your transition to web3 is seamless,
            beneficial, and aligned with your long-term vision.
          </p>
        </div>
        <div className="w-full md:w-1/3 md:pl-6 p-4">
          <img src={solidity} alt="solidity" className="w-30 h-30" />
        </div>
      </div>

      <div className="p-4 md:p-8 lg:p-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Our Web3 Consulting and Development Services
        </h2>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">Web3 strategy</h3>
            <p>
              We look at Tokens and NFTs as potential ingredients in Web3
              strategies but they are not the be all and end all. Decentralized
              storage, processing, identity and ownership will take the world by
              storm.
            </p>

            <p>
              We work closely with you to understand your business goals and
              challenges, and then craft a web3 strategy that aligns with your
              objectives.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">
              Decentralized application design
            </h3>
            <p>
              We analyze your business requirements and support the design of a
              decentralized application including token design if necessary.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">Solidity development</h3>
            <p>
              We develop the smart contracts powering your decentralized
              application.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">Deployment</h3>
            <p>
              We offer deployment services of the smart contracts on any
              EVM-compatible chain and support you in key management and smart
              contract maintenance so you can focus on the business and not lose
              valuable time on managing smart contracts.
            </p>
          </div>

          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">Crypto payments</h3>
            <p>
              We guide you through complixities of decentralized payment rails.
              The options seem overwhelming. L1 or L2 ? Working with a payment
              provider or hosting your own payment server? Which crypto assets
              to accept?
            </p>
            <p>
              We assist you in finding the solution that fits you and offer
              technical support in integrating payments into your process.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">DeFi protocols</h3>
            <p>
              We have a deep understanding of DeFi protocols. We integrate your
              business processes with DeFi protocols to improve your access to
              new funding channels, hedge risks, find additional return on idle
              cash, find arbitrage opportunities, ...
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded"></div>
        </div>
      </div>
      <CaseStudiesWeb3 />
      <ContactUsOverview />
    </div>
  );
};

export default Web3DevPage;
