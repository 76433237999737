import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import reginald from "../images/reginald-grayscale.png"; // update the path if necessary

const Who = () => {
  return (
    <div className="flex flex-wrap md:flex-nowrap items-center">
      <div className="w-full md:w-3/4 md:pr-6 p-4 ">
        <h2 className="text-4xl font-bold mb-6 text-center">Who</h2>
        <div className="flex flex-wrap items-center">
          <b className="text-2xl">Reginald Dewil</b>
          <Link
            to="https://www.linkedin.com/in/reginalddewil/"
            target="_blank"
            className="flex items-center ml-2 flex-wrap"
          >
            <FaLinkedin />
          </Link>
        </div>
        <p className="flex-wrap text-justify">
          is a consultant and developer with over a decade of experience in
          optimization algorithm design and implementation. He is currently
          exploring in depth the emerging field of web3. My professional passion
          lies in tackling complex problems and delivering results.
        </p>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        At KU Leuven, Reginald obtained:
        <ul className="list-disc ml-6 mb-6">
          <li>PhD Engineering Science - optimization algorithms</li>
          <li> Msc Industrial Management - transportation & logistics </li>
          <li>Msc Engineering Technology -chemical engineering</li>
        </ul>
        Consultancy experience:
        <ul className="list-disc ml-6 mb-6">
          <li>Hatamoto (2022-present)</li>
          <li>Conundra, algorithm engineer (2016) </li>
          <li>Accenture, analyst (2008-2009)</li>
        </ul>
        Academic career:
        <ul className="list-disc ml-6 mb-6">
          <li>
            Assistant Professor, Decision-making in industry 4.0, KU Leuven
            (2020-2022)
          </li>
          <li>
            Assistant Professor, Supply Chain Analytics, Vrije Universiteit
            Amsterdam (2019)
          </li>
          <li>Post-doc (2014-2016)(2017-2018)</li>
          <li>PhD researcher (2009-2014)</li>
        </ul>
        <p>
          Throughout the years, Reginald worked on a large variety of topics
          going from process planning in laser cutting toolpath optimization to
          vehicle routing, human-robot collaborative scheduling, satellite
          schedulingintegrated picker-agv scheduling and routing in an
          e-commerce warehouse, and many more...
          <br />
          Reginald is very skilled at drilling to the core issues of a problem
          and designing a custom algorithm that exploits the specific problem
          structure in order to generate near-optimal solutions.
        </p>
      </div>

      <div className="w-full md:w-1/4 md:pl-6 p-4">
        <img src={reginald} alt="Reginald Dewil" className="w-30 h-30" />
      </div>
    </div>
  );
};

export default Who;
