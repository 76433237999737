import React from "react";
import { Link } from "react-router-dom";

import mobaImage from "../images/cases/moba.jpg";
import orderPicking from "../images/cases/intOrderPicking.png";
import vrp from "../images/cases/vrp.png";
import patrolRouting from "../images/cases/patrolrouting.png";
import toolPath from "../images/cases/laserCutting.jpg";
import humanRobot from "../images/cases/humanRobot.png";
import packingLine from "../images/cases/packingLine.png";
import electricTSP from "../images/cases/electricTSP.png";
import wasteCollection from "../images/cases/wasteCollection.png";
import layoutPlanning from "../images/cases/layoutPlanning.png";
import satelliteScheduling from "../images/cases/satelliteScheduling.png";
import polymerSeparation from "../images/cases/polymerSeparationSteps.png";

const CaseStudiesOptimization = () => {
  // Replace this with your actual case studies data
  const caseStudiesData = [
    {
      title: "Egg sorting",
      description:
        "A fast simulation-optimizaton approach was able to automate scheduling in a fast paced dynamic egg sorting operation and improve daily operating profit by",
      gain: "8.35%",
      imageUrl: mobaImage,
      id: "egg-sorting",
      hasLink: false,
      internalLink: true,
    },
    {
      title: "Picker scheduling",
      description:
        "Integrated robot - human picker scheduling in high volume e-commerce warehouse. Reduced operation cost by",
      gain: "14%",
      imageUrl: orderPicking,
      id: "order-picking",
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://www.sciencedirect.com/science/article/abs/pii/S1569190X23000667",
    },
    {
      title: "Vehicle routing",
      description:
        "Developed custom solvers for non-standard use cases involving electric trucks or independently routing tractors and trailers.",
      imageUrl: vrp, // Add image URL
      id: "vrp",
      hasLink: false,
    },
    {
      title: "Satellite scheduling",
      description:
        "Exploited the problem structure of a satellite fleet scheduling problem. Generated solutions that outperformed the state of the art by",
      gain: "20.33%",
      imageUrl: satelliteScheduling,
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://www.sciencedirect.com/science/article/abs/pii/S0305054819301510",
    },
    {
      title: "Human-robot scheduling",
      description:
        "Developed a multi-objective genetic algorithm that finds (near) pareto-optimal solutions with regards to makespan and ergonomic load for workers",
      imageUrl: humanRobot,
      hasLink: false,
    },
    {
      title: "Waste collection",
      description:
        "Development of two optimization approaches to optimize waste collection in a large municipal area on both the tactical an operational level. ",
      imageUrl: wasteCollection,
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://www.sciencedirect.com/science/article/abs/pii/S0377221722008815",
    },
    {
      title: "Police patrol routing",
      description:
        "Solved a patrol routing problem of arbitrary size to optimality in milliseconds opening the door to embed this approach in more elaborate optimization routines.",
      imageUrl: patrolRouting,
      id: "patrol-routing",
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://www.sciencedirect.com/science/article/abs/pii/S0377221715004798",
    },
    {
      title: "Packing line scheduling",
      description:
        "Developed several scheduling engines for a packing line at a large brewer taking into account many practical constraints and SC backup capacity.",
      imageUrl: packingLine,
      hasLink: false,
    },
    {
      title: "Polymer separation",
      description:
        "Developed a very fast optimal solution approach for determining the minimal cost sequence to recover polymers from a mixture of recovered plastics.",
      imageUrl: polymerSeparation,
      hasLink: false,
    },
    {
      title: "Electric truck routing",
      description: "", //TODO: short description electric truck routing
      imageUrl: electricTSP,
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://www.sciencedirect.com/science/article/abs/pii/S0957417419303732",
    },
    {
      title: "Laser cutting",
      description:
        "Optimizing the cutting path of the cutting head of a laser cutting machine to minimize total processing time taking into account complex precedence constraints and heat accumulation in the metal sheet",
      imageUrl: toolPath,
      hasLink: false,
    },
    {
      title: "Layout planning",
      description: "", //TODO: short description layout scheduling
      imageUrl: layoutPlanning,
      hasLink: true,
      internalLink: false,
      externalLink:
        "https://link.springer.com/article/10.1007/s00521-021-06817-7",
    },
  ];

  const CaseStudyComponent = (caseStudy) => (
    <div
      key={caseStudy.id}
      className="flex flex-col items-center bg-white shadow-md p-4"
    >
      <div
        className="h-32 w-32 bg-cover bg-center rounded-full"
        style={{ backgroundImage: `url(${caseStudy.imageUrl})` }}
      />
      <h3 className="mt-4 font-semibold text-lg">{caseStudy.title}</h3>
      <p className="mt-2 text-gray-600">
        {caseStudy.description} <b>{caseStudy.gain}</b>
      </p>
    </div>
  );

  return (
    <div>
      <h1 className="text-4xl m-10 font-bold text-center">Past cases</h1>
      <div className="flex overflow-x-scroll p-4 space-x-4">
        {caseStudiesData.map((caseStudy) => {
          const caseStudyComponent = <CaseStudyComponent {...caseStudy} />;
          if (!caseStudy.hasLink) {
            return caseStudyComponent;
          } else if (!caseStudy.internalLink) {
            return (
              <a
                href={caseStudy.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>{caseStudyComponent}</div>
              </a>
            );
          } else {
            return (
              <Link to={`/case-studies/${caseStudy.id}`}>
                <div>{caseStudyComponent}</div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default CaseStudiesOptimization;
