import React from "react";
import or from "../images/or.jpg"; // update the path if necessary
import CaseStudiesOptimization from "../components/CaseStudiesOptimization";
import WayOfWorking from "../components/WayOfWorking";
import ContactUsOverview from "../components/ContactUsOverview";

const OptimizationPage = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      <section className="py-10 text-center">
        <h1 className="text-4xl mb-4 font-bold ">Optimization </h1>
        <h2 className="text-2xl mb-2">
          We power your planning process with state-of-the-art heuristics,
          optimization, and machine learning.
        </h2>
      </section>
      <div className=" text-xl flex flex-wrap md:flex-nowrap items-center">
        <div className="prose lg:prose-xl w-full md:w-2/3">
          <p className="mb-2">
            At Hatamoto, we harness the power of Operations Research (OR),
            enhanced by the latest Artificial Intelligence (AI) techniques, to
            solve complex business problems. For the uninitiated, OR is a field
            of study that uses advanced analytical methods to make better
            decisions. Originating from military operations during World War II,
            OR has evolved into a pillar of modern business strategy, dealing
            with the application of advanced analytical methods to aid better
            decision-making.
          </p>
          <p className="mb-2">
            With a rich history and proven methodologies, OR holds the key to
            tackling a wide range of industrial, financial, and societal issues.
            It’s about finding the optimal solution to complex problems, from
            logistics and supply chain management to scheduling and process
            optimization. However, when coupled with the predictive prowess of
            AI, its capacity to drive business growth reaches new heights.
          </p>
          <p className="mb-2">
            Our team at Hatamoto, with over 14 years of experience in teaching,
            researching, developing, and successfully implementing OR models, is
            adept at using this powerful combination to navigate business
            complexities. We have been instrumental in driving operational
            efficiency and productivity, reducing costs, and optimizing
            processes across various industries.
          </p>
          <p className="mb-2">
            If you're ready to leverage the power of OR, bolstered by AI, to
            transform your business, you're in the right place. Hatamoto is not
            just a service provider - we're a partner in your business's journey
            towards efficiency and success.
          </p>
        </div>
        <div className="w-full md:w-1/3 md:pl-6 p-4">
          <img
            src={or}
            alt="operations-research-lifecycle"
            className="w-30 h-30"
          />
        </div>
      </div>

      <div className="p-4 md:p-8 lg:p-12">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-8">
          Our Optimization Services
        </h2>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">
              Business Process Modelling & Driver Analysis
            </h3>

            <p>
              We do a in-depth analysis of a business proces that potentially
              can be improved using optimization and identify the profit or cost
              drivers and hard and soft constraints. From this we synthesize
              avenues for improvement.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">
              Problem Modelling (Mixed Integer Programming)
            </h3>
            <p>
              We model the given optimizaton problem in efficient mathematical
              models that can be fed to optimal mathematical solvers. We advise
              clients on the appropriateness of exact versus heuristic models.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">
              Optimization Algorithm Design & Implementation
            </h3>
            <p>
              For many practical large scale problems, an exact mathematical
              approach is not suited as it requires huge calculation times. We
              design and develop custom heuristic algorithms that exploit the
              problem structure and generate near-optimal solutions in very
              short computation times. Such algorithms are extremely well suited
              for operational dynamic problems or for tactical decision making
              where many scenarios need to be evaluated.
            </p>
          </div>
          <div className="p-4 bg-white shadow rounded">
            <h3 className="text-xl font-bold mb-2">Integrating Solvers</h3>
            <p>
              We like optimization challenges by themselves, but we like getting
              good results for our clients even better. A solver by itself does
              not solve the customer's problem. Therefore we assist our clients
              in integrating our or 3rd party solvers in their business
              processes to make sure the calculated savings are realized in the
              field.
            </p>
          </div>
        </div>
      </div>
      <CaseStudiesOptimization />
      <WayOfWorking />
      <ContactUsOverview />
    </div>
  );
};

export default OptimizationPage;
