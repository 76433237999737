import React from "react";

import Hero from "../components/HeroSection";
import ServicesOverview from "../components/ServicesOverview";
import AboutUsOverview from "../components/AboutUsOverview";
import ContactUsOverview from "../components/ContactUsOverview";

const HomePage = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      <Hero />
      <ServicesOverview />
      <AboutUsOverview />
      <ContactUsOverview />
    </div>
  );
};

export default HomePage;
