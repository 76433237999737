import React from "react";
import { Link } from "react-router-dom";
import "../css/Herosection.css";
import heroImage from "../images/banner.png";

const HeroSection = () => {
  return (
    <div className="hero-container bg-cover bg-center relative z-2">
      <h1 className="text-5xl font-bold text-white py-2">
        Technology delivered
      </h1>
      <p className="text-lg text-white px-16 py-3 text-center">
        At Hatamoto, we leverage advanced optimization algorithms, AI, and web3
        technology to drive growth, efficiency, and a competitive edge for our
        clients.
      </p>
      <div className="mt-8">
        <Link
          to="/contact"
          className="bg-blue-500 px-6 py-3 rounded-full font-semibold mr-2 "
        >
          Contact Us
        </Link>
        <Link
          to="/about"
          className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold"
        >
          Learn More About Us
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
