import React from "react";
import profilePicture from "../images/reginald.jpg"; // Update this path to the location of your profile picture.

const ContactUs = () => {
  return (
    <div className="p-6 md:p-12 flex flex-wrap">
      <div className="w-full md:w-1/2">
        <h1 className="text-4xl mb-6 text-center font-bold">Contact Us</h1>

        <h2 className="text-2xl mb-4 text-center font-bold">
          Contact Information
        </h2>
        <p className="text-lg mb-4 text-center">
          Email: reginald@hatamoto.be <br />
        </p>

        <h2 className="text-2xl mb-4 text-center font-bold">
          Send Us a Message
        </h2>
        <form className="w-full max-w-lg mx-auto">
          {/* Your form inputs and submit button here */}
        </form>
      </div>

      <div className="w-full md:w-1/2 flex justify-center items-center md:items-start">
        <img
          src={profilePicture}
          alt="Your Name"
          className="w-64 h-64 object-cover rounded-full shadow-lg"
        />
      </div>
    </div>
  );
};

export default ContactUs;
