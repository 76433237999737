import React from "react";

import banner from "../images/banner.png"; // update the path if necessary

const AboutUs = () => {
  return (
    <div className="flex flex-wrap md:flex-nowrap items-center">
      <div className="w-full md:w-1/2 md:pr-6 p-4">
        <h2 className="text-4xl font-bold mb-6">About Us</h2>
        <p className="text-justify">
          In the past decade, we have seen processes become more digitized
          optimization algorithms and hardware have become incredably powerful.
          I started Hatamoto with the vision of bringing these technologies to
          manufacturing and logistics companies where we often see a very slow
          adoption process. At the same time, a new set of technologies in AI
          and blockchain are emerging and unlocking new business models and
          Hatamoto wants to support companies taking their first steps in these
          fields.
        </p>
        <p>
          At Hatamoto, we focus on the development of a relationship with your
          company as a trusted business advisor. Hatamoto offers business
          consulting and software development services in the areas of
          optimization, planning and scheduling, and web3.
        </p>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <p>
          The model Hatamoto offers goes beyond simple consultancy. Our model is
          to understand <b>your business</b> and craft custom solutions that
          serve your business. We offer state-of-the art development services
          without trying to reinvent the wheel. If an existing commercial
          software is available that is perfectly suited for the job, we will
          advise our clients towards those solutions and support its
          implementation or integration.
        </p>
      </div>
      <div className="w-full md:w-1/2 md:pl-6 p-4">
        <img src={banner} alt="placeholder" className="w-full h-50" />
      </div>
    </div>
  );
};

export default AboutUs;
