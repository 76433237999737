import React from "react";
import { Link } from "react-router-dom";

import gridTrading from "../images/web3/gridTrading.jpg";
import commodities from "../images/web3/commodities.jpg";
import tokenvest from "../images/web3/logoTokenVest.png";

const CaseStudiesWeb3 = () => {
  // Replace this with your actual case studies data
  const caseStudiesData = [
    {
      title: "Tokenization",
      description:
        "We facilitate the tokenization of any type of debt instrument directly integrated with KYC/AML process flow which are enforced on chain",
      imageUrl: tokenvest,
      id: "tokenization",
    },
    {
      title: "Synthetic commodities",
      description:
        "Developed a set of smart contracts to mint synthetic commodity tokens. Price is stabilized through game theory and exogenous crypto-asset collateral.",
      imageUrl: commodities,
      id: "commoditySynths",
    },
    {
      title: "Portfolio Rebalancer",
      description:
        "Grid trader and portfolio rebalancer combined in one and executed fully on-chain.",
      imageUrl: gridTrading, // Add image URL
      id: "gridTrading",
    },
  ];

  return (
    <div>
      <h1 className="text-4xl m-10 font-bold text-center">Cases</h1>

      <div className="flex overflow-x-scroll p-4 space-x-4">
        {caseStudiesData.map((caseStudy) => (
          //<Link to={`/case-studies/${caseStudy.id}`} key={caseStudy.id}>
          <div
            key={caseStudy.id}
            className="flex flex-col items-center bg-white shadow-md p-4"
          >
            <div
              className="h-32 w-32 bg-cover bg-center rounded-full"
              style={{ backgroundImage: `url(${caseStudy.imageUrl})` }}
            />
            <h3 className="mt-4 font-semibold text-lg">{caseStudy.title}</h3>
            <p className="mt-2 text-gray-600">{caseStudy.description}</p>
          </div>
          //</Link>
        ))}
      </div>
    </div>
  );
};

export default CaseStudiesWeb3;
