import React from "react";
import ServiceCard from "./ServiceCard";

const ServicesOverview = () => {
  const services = [
    {
      title: "Optimization Consulting",
      description:
        "Together we search for optimizing your operations and leverage state-of-the-art optimization algorithms achieve unprecedented efficiency.",
      link: "/services/optimization",
    },
    {
      title: "Process Automation & Digitalization",
      description:
        "Hatamoto helps you automate and digitalize your processes. We offer comprehensive consulting services to help businesses formulate a digitalization strategy and integrate digital technologies. From process mining to complex process automation, Hatamoto builds and deploys efficient solutions.",
      link: "/services/automation",
    },
    {
      title: "Web3 Development & Consulting",
      description:
        "Hatamoto supports you through the intricacies of the decentralized web. We offer comprehensive consulting services to help businesses formulate a Web3 strategy and integrate Web3 technologies. From token creation to complex DApps, Hatamoto builds and deploys efficient smart contracts.",
      link: "/services/web3-dev",
    },
    {
      title: "Business Training & Workshops",
      description:
        "Hatamoto offers business accumen training for engineers and technical training for business people. We also offer workshops to help you understand the potential of Web3 technologies and how they can be applied to your business.",
      link: "/services/training",
    },
  ];

  return (
    <div className="container mx-auto py-5">
      <h1 className="text-4xl font-bold text-center">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
        {services.map((service) => (
          <ServiceCard
            key={service.title}
            title={service.title}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesOverview;
