import img_pex from "../images/PEX.png";
import img_pex_network from "../images/PEX_Network.png";
import {
  PEOPLE_EXPONENTIAL_URL,
  PEOPLE_EXPONENTIAL_NETWORK_URL,
} from "../constants";

const AboutUsOverview = () => {
  return (
    <div className="p-6 md:p-12">
      <h1 className="text-4xl mb-6 text-center font-bold">About Hatamoto</h1>
      <div className="grid md:grid-cols-3 gap-6">
        <div className="text-lg">
          <p>
            Short and simple, <b>we deliver</b>.
          </p>
          <p className="mt-2">
            We unpack your business problem to its core functioning components
            and identify the most appropriate solution.
          </p>
        </div>
        <div>
          <p className="text-lg">
            We have deep expertise in <b>optimization</b> algorithm research and
            algorithm implementation. We have 14 years of experience in solving
            real business problems in complex and messy operational
            environments.
          </p>
        </div>
        <div>
          <p className="text-lg">
            We have been following <b>blockchain and smart contract</b>{" "}
            development closely since its inception. We have hands-on experience
            in functional and technical design and development of web3
            applications.
          </p>
        </div>
        <p className="text-lg text-justify"></p>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center mt-6">
        <div className="w-1/4 md:w-1/2 ml-4 flex justify-end">
          <a
            href={PEOPLE_EXPONENTIAL_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            <img
              src={img_pex}
              alt="People Exponential Logo"
              className="w-1/2 ml-40"
            />
          </a>
        </div>
        <div className="w-1/2 md:w-1/2 m-4 flex flex-col items-center text-center">
          <h1 className="text-lg md:text-xl text-center md:text-left flex-grow">
            <b>News!</b>
          </h1>
          <div>
            We've recently joined forces with other experts in communication,
            business, and digital marketing to expand our capabilities in
            effectively implementing and anchoring transformation projects in
            day to day workflows. We joined with{" "}
            <a
              href={PEOPLE_EXPONENTIAL_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              People Exponential
            </a>{" "}
            and the{" "}
            <a
              href={PEOPLE_EXPONENTIAL_NETWORK_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              People Exponential Network
            </a>
            .
          </div>
        </div>
        <div className="w-1/4 md:w-1/2 ml-4 flex justify-end">
          <a
            href={PEOPLE_EXPONENTIAL_NETWORK_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img_pex_network}
              alt="People Exponential Network Logo"
              className="w-1/2"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutUsOverview;
