import React from "react";
import meeting from "../images/businessMeeting.png"; // update the path if necessary

const Why = () => {
  return (
    <div className="flex flex-wrap md:flex-nowrap items-center">
      <div className="w-full md:w-1/2 md:pl-6 p-4">
        <img src={meeting} alt="placeholder" className="w-full h-50" />
      </div>
      <div className="w-full md:w-1/2 md:pr-6 p-4">
        <div className="p-6 md:p-12">
          <h2 className="text-4xl font-bold mb-6 text-center">
            Why Choose Hatamoto?
          </h2>
          <p className="mb-6">
            A hatamoto was a high ranking samurai in the direct trusted service
            of the shogun in feudal Japan. We chose this as our company name
            since we believe in order to truly understand your business and
            offer the best advice it is important to build deep trust and
            loyalty.
          </p>
          <p className="mb-6">
            Choosing to work with our company for your planning and scheduling
            needs is not just a decision to improve your operational efficiency;
            it's a strategic move to transform your business. Here's why we
            stand out:
          </p>
          <ul className="list-disc ml-6 mb-6">
            <li className="mb-2">
              <span className="font-bold">Expertise:</span> We have deep
              technical expertise in optimization and algorithm design as well
              as smart contract development. Our expertise reaches further than
              simple technical experience. We actually understand the business
              processes that we are trying optimize in manufacturing and
              logistics or the financial stack that we are trying to disrupt
              using a new Defi application.
            </li>
            <li className="mb-2">
              <span className="font-bold">Custom Solutions:</span> We do not
              believe in a one-size-fits-all approach. We understand that every
              business is unique, so we tailor our solutions to fit your
              specific requirements, ensuring maximum efficiency and
              cost-effectiveness.
            </li>
            <li className="mb-2">
              <span className="font-bold">Latest Technology:</span> We use
              cutting-edge technology to provide you with the best possible
              solutions. We strive to continually stay at the forefront of
              innovation to deliver solutions that not only solve today's
              problems but also prepare you for future challenges.
            </li>
            <li className="mb-2">
              <span className="font-bold">Results-oriented:</span> Our focus is
              on delivering tangible results that positively impact your bottom
              line. Our solutions aim to reduce wasted time, increase
              productivity, streamline processes, and ultimately enhance
              profitability.
            </li>
            <li className="mb-2">
              <span className="font-bold">Post-implementation Support:</span> We
              are committed to the success of your business even after we've
              implemented our solutions. We offer continuous support and are
              always ready to refine and adjust our strategies as your business
              grows and evolves.
            </li>
          </ul>
          <p>
            In conclusion, by choosing us, you're not just getting a service
            provider but a dedicated partner committed to driving the success of
            your business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Why;
