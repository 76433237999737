import React from "react";
import { Link } from "react-router-dom";

const ContactUsOverview = () => {
  return (
    <section className="bg-blue-100 p-10 text-center">
      <h2 className="text-4xl mb-4">Ready to get started?</h2>
      <p className="text-xl mb-8">
        Contact us today and our team will be happy to assist you!
      </p>
      <Link
        to="/contact"
        className="bg-blue-500 text-white px-6 py-3 rounded-full font-semibold"
      >
        Contact Us
      </Link>
    </section>
  );
};

export default ContactUsOverview;
