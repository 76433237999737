import React from "react";

const PatrolRouting = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      //TODO: add content for patrol routing
    </div>
  );
};

export default PatrolRouting;
