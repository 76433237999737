import React from "react";
import { Link } from "react-router-dom";
import ln from "../images/ln.png"; // update the path if necessary
import { MdOutlineEmail } from "react-icons/md";
import { GiSmartphone } from "react-icons/gi";
import logo from "../images/logo.png"; // update the path if necessary

const Footer = () => {
  return (
    <div className="bg-slate-400 text-white px-10">
      <header className="flex flex-wrap justify-between items-top py-4">
        <div className="w-full md:w-auto">
          <Link
            to="./"
            className="flex items-center justify-center md:justify-start"
          >
            <img
              src={logo}
              alt="Hatamoto - precision delivered"
              className="h-4 md:h-12 w-auto"
            />
            <span className="text-lg md:text-2xl font-bold">HATAMOTO</span>
          </Link>
          <div>
            <Link
              to="https://www.linkedin.com/company/94228073/"
              target="_blank"
              className="flex items-center ml-2"
            >
              <img
                src={ln}
                alt="Hatamoto - technology delivered"
                className="h-8 w-auto"
              />
            </Link>
          </div>
        </div>

        <div>
          <h1>GET IN TOUCH</h1>
          <hr class="w-50 h-1 mx-left my-4 bg-gray-100 border-0 rounded md:my-2 dark:bg-gray-700" />
          <div className="flex items-center">
            <MdOutlineEmail className="mr-2" />
            <span>reginald@hatamoto.be</span>
          </div>
          <div className="flex items-center">
            <GiSmartphone className="mr-2" />
            <span>+32 (0) 479 / 30 81 63</span>
          </div>
        </div>
      </header>
      <div>
        © Hatamoto 2023 • All AI were treated humanely in the making of this
        website.
      </div>
    </div>
  );
};

export default Footer;
