import React from "react";
import process from "../images/hatamoto-process.png"; // update the path if necessary

const WayOfWorking = () => {
  return (
    <div className="mt-10">
      <h2 className="text-4xl font-bold text-center">Way of working?</h2>
      <div className="flex flex-wrap md:flex-nowrap items-center">
        <div className="w-full md:w-1/2 md:pl-2 p-1">
          <img src={process} alt="placeholder" className="w-full h-50" />
        </div>
        <div className="w-full md:w-1/2 md:pr-6 p-1">
          <div className="p-6 md:p-12">
            <p className="mb-6 text-xl">
              At Hatamoto, we don't believe in big bang implementations but are
              strong believers in small experiments to try out new ideas and
              evaluate intermediate results which guide us to our next
              improvement.
            </p>

            <ol className="ml-6 mb-6 list-decimal list-inside space-y-2">
              <li className="p-1">
                <span className="font-bold">Analyze:</span> We do a high level
                scan to evaluate the business and identify proceses that
                potentially can be improved using optimization. We take into
                account technological readiness of the client.
              </li>
              <li className="p-1">
                <span className="font-bold">Understand:</span> We do a deep dive
                into the selected business processes to fully understand what
                are the exact cost and revenue drivers and what are the active
                constraints. We try to understand the specifics of your process
                instead of by default trying to fit it in an existing solution.
              </li>
              <li className="p-1">
                <span className="font-bold">Iterate:</span> We develop or select
                straightforward optimization approaches to generate initial
                solutions. This forms the basis to sollicit additional input on
                business drivers and constraints. And sometimes we have a
                satisfactory solver.
              </li>
              <li className="p-1">
                <span className="font-bold">Determine AS-IS:</span> Once we
                understand the business and know what a good solution looks
                like, we select specific scenarios with their current solutions.
                These will serve as the AS-IS baseline against which we compare
                the optimized solutions.
              </li>
              <li className="p-1">
                <span className="font-bold">Design solution:</span> We design a
                a tailored optimization approach that exploits the client's
                specific problem structure to generate near-optimal solutions in
                short computation times. If good commercial solutions are
                available we support vendor selection. No need to reinvent the
                wheel.
              </li>
              <li className="p-1">
                <span className="font-bold">Implement:</span> We implement the
                chosen solution approach and integrate with client proceses.
              </li>
              <li className="p-1">
                <span className="font-bold">Evaluate:</span> We evaluate the
                generated solutions and compare against the baseline. During
                operations, as more problem instances become available, we can
                continue to improve solver effectiveness.
              </li>
            </ol>
            <p className="text-xl">
              This approach has proven itself across multiple large-scale
              problems. We have experience in static and dynamic problems and in
              stochastic and deterministic problems.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WayOfWorking;
