import React from "react";

function TrainingPage() {
  return (
    <div>
      <h1>Welcome to the Training Page!</h1>
      {/* Add your content here */}
    </div>
  );
}

export default TrainingPage;
