import React from "react";
import automation from "../images/automation.png"; // update the path if necessary
import ContactUsOverview from "../components/ContactUsOverview";
import CaseStudiesAutomation from "../components/CaseStudiesAutomation";

const AutomationPage = () => {
  return (
    <div>
      <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
        <section className="py-10 text-center">
          <h1 className="text-4xl mb-4 font-bold ">
            Business Process Automation
          </h1>
          <h2 className="text-2xl mb-2">Streamline processes.</h2>
        </section>
        <div className="flex flex-wrap md:flex-nowrap items-center">
          <div className="prose lg:prose-xl w-full md:w-2/3">
            <p className="mb-2">
              Business process automation (BPA) is the use of technology to
              automate repeatable, day-to-day tasks. It is a key component of
              digital transformation, enabling organizations to streamline
              processes, increase efficiency, and reduce costs. We at Hatamoto
              understand the importance of BPA in today's competitive business
              environments but also recognize that it can be challenging to
              implement. That's why we offer a range of services to help you
              automate your business processes, from consulting and strategy to
              implementation and support.
            </p>
            <p>
              For large scale projects, we work together with recognized
              implementation partners and for smaller projects we do shy away
              from getting the job done in tools the users are familiar with.
            </p>
          </div>
          <div className="w-full md:w-1/3 md:pl-6 p-4">
            <img src={automation} alt="automation" className="w-30 h-30" />
          </div>
        </div>
      </div>
      <CaseStudiesAutomation />
      <ContactUsOverview />
    </div>
  );
};

export default AutomationPage;
