import React from "react";
import { Link } from "react-router-dom";

import exitReportGeneration from "../images/cases/severencePackageGeneration.png";
import masterProductionSchedulingTool from "../images/cases/mps.png";

const CaseStudiesAutomation = () => {
  // Replace this with your actual case studies data
  const caseStudiesData = [
    {
      title: "MPS Tool",
      description:
        "Developed a tool in Excel VB to generate a master production schedule for a large aerospace company. The tool uses ERP data to generate a feasible MPS using custom decision rules and allows for user modifications. The tool first generates an aggregate plan and then splits the plan into a detailed item-level plan. The tool utilizes basic decision rules to get users comfortable using automation in their planning process but is exensible to more advanced optimization ranging from mixed integer linear programming to metaheuristics and AI.",
      imageUrl: masterProductionSchedulingTool,
      id: "mps",
      hasLink: false,
      internalLink: false,
    },
    {
      title: "Severance Package Generator",
      description:
        "An Excel VB based tool that generates detailed severance packages for employees based on their years of service and salary. The tool is used by a large petrochemical production company to generate severance packages for employees who are being laid off as part of a specific negotiated settlement. It is fed directly from the company's main ERP system and other inputs.",
      imageUrl: exitReportGeneration,
      id: "order-picking",
      hasLink: false,
      internalLink: false,
    },
  ];

  const CaseStudyComponent = (caseStudy) => (
    <div
      key={caseStudy.id}
      className="flex flex-col items-center bg-white shadow-md p-4"
    >
      <div
        className="h-32 w-32 bg-cover bg-center rounded-full"
        style={{ backgroundImage: `url(${caseStudy.imageUrl})` }}
      />
      <h3 className="mt-4 font-semibold text-lg">{caseStudy.title}</h3>
      <p className="mt-2 text-gray-600">{caseStudy.description}</p>
    </div>
  );

  return (
    <div>
      <h1 className="text-4xl m-10 font-bold text-center">Past cases</h1>
      <div className="flex overflow-x-scroll p-4 space-x-4">
        {caseStudiesData.map((caseStudy) => {
          const caseStudyComponent = <CaseStudyComponent {...caseStudy} />;
          if (!caseStudy.hasLink) {
            return caseStudyComponent;
          } else if (!caseStudy.internalLink) {
            return (
              <a
                href={caseStudy.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>{caseStudyComponent}</div>
              </a>
            );
          } else {
            return (
              <Link to={`/case-studies/${caseStudy.id}`}>
                <div>{caseStudyComponent}</div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default CaseStudiesAutomation;
