import React from "react";

const PickingOpt = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32">
      //ToDO: add content for picking optimization
    </div>
  );
};

export default PickingOpt;
