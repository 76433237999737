import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png"; // update the path if necessary

const Header = () => {
  return (
    <header className="flex flex-wrap justify-between items-center py-4 px-6 bg-slate-400 text-white h-auto md:h-24">
      <div className="w-full md:w-auto">
        <Link
          to="./"
          className="flex items-center justify-center md:justify-start"
        >
          <img
            src={logo}
            alt="Hatamoto - precision delivered"
            className="h-16 md:h-24 w-auto"
          />
          <span className="text-lg md:text-2xl font-bold">HATAMOTO</span>
        </Link>
      </div>
      <nav className="w-full md:w-auto">
        <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-4 text-lg md:text-xl justify-center md:justify-start">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <div className="relative group">
              <span>Services</span>
              {/* Dropdown Menu */}
              <div className="absolute right-0 mt-2 w-48 bg-white text-black p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10">
                <ul>
                  <li>
                    <Link to="/services/optimization">Optimization</Link>
                  </li>
                  <li>
                    <Link to="/services/automation">Automation</Link>
                  </li>
                  <li>
                    <Link to="/services/web3-dev">
                      Web3 Development & Consulting
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/training">Training</Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
